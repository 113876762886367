import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import AnexarConsentimentoDialog from './AnexarConsentimentoDialog';

import './ConsentimentoPaciente.scss';
interface ConsentimentoPacienteProps {
  loadDadosPessoais: () => void;
}

const ConsentimentoPaciente = ({
  loadDadosPessoais,
}: ConsentimentoPacienteProps) => {
  const [loading, setLoading] = useState(false);
  const { idEmpresa } = useAppSelector(state => state.user);
  const { idPaciente } = useParams();

  const anexarDisclosure = useDisclosure({ opened: false });
  const confirmarExclusaoDisclosure = useDisclosure({ opened: false });

  const { watch, setValue } = useFormContext();

  const watchTermoConsentimentoFilePath = watch('termoConsentimentoFilePath');
  const watchTermoConsentimentoUploadUrl = watch('termoConsentimentoUploadUrl');
  const watchTermoConsentimentoFileName = watch('termoConsentimentoFileName');
  const watchTermoConsentimentoFileSize = watch('termoConsentimentoFileSize');
  const watchTermoConsentimentoDataOcorrencia = watch(
    'termoConsentimentoDataOcorrencia',
  );

  const onExcluirArquivo = async () => {
    setValue('termoConsentimentoFilePath', null);
    setValue('termoConsentimentoDataOcorrencia', null);
    const payload = {
      idEmpresa,
      termoConsentimentoFilePath: null,
      termoConsentimentoDataOcorrencia: null,
    };
    try {
      setLoading(true);
      confirmarExclusaoDisclosure.close();
      await PacienteAPI.updatePacienteDadosPessoais(
        Number(idPaciente),
        payload,
        false,
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="consentimento-paciente"
      className={`p-d-flex p-flex-column p-col-12 p-lg-6 p-xl-5 br-8px p-gap-2 p-py-3 p-px-2 termo-container`}
    >
      <SimpleText medium>Concentimento do paciente</SimpleText>
      {watchTermoConsentimentoFilePath ? (
        <div className="p-d-flex p-flex-column p-gap-3">
          <div className="p-d-flex p-ai-center p-jc-between bg-96 br-8px p-p-1">
            <Button
              type="button"
              label={watchTermoConsentimentoFileName || `Arquivo anexo`}
              btnType="green-link"
              icon="fas fa-file"
              iconPos="left"
              onClick={() => {
                window.open(watchTermoConsentimentoUploadUrl);
              }}
              disabled={!watchTermoConsentimentoUploadUrl}
            />
            <div className="p-d-flex p-ai-center p-gap-2">
              <SimpleText fontColor="color_60">
                {' '}
                {watchTermoConsentimentoFileSize
                  ? `${watchTermoConsentimentoFileSize} KB`
                  : ''}
              </SimpleText>
              <Button
                icon="fas fa-trash"
                btnType="gray"
                onClick={() => confirmarExclusaoDisclosure.open()}
                loading={loading}
              />
            </div>
          </div>
          <SimpleText fontColor="color_60" className="p-ml-1">
            {`Data da ocorrencia: ${
              watchTermoConsentimentoDataOcorrencia
                ? dayjs(watchTermoConsentimentoDataOcorrencia).format(
                    'DD/MM/YYYY',
                  )
                : ''
            }`}
          </SimpleText>
        </div>
      ) : (
        <Button
          className="p-col-6"
          type="button"
          label="Anexar arquivo"
          btnType="tonal"
          icon="fas fa-paperclip"
          iconPos="left"
          onClick={() => anexarDisclosure.open()}
        />
      )}
      {anexarDisclosure.isOpen && (
        <AnexarConsentimentoDialog
          {...anexarDisclosure}
          loadDadosPessoais={loadDadosPessoais}
        />
      )}
      {confirmarExclusaoDisclosure.isOpen && (
        <ConfirmDialog
          {...confirmarExclusaoDisclosure}
          visible={confirmarExclusaoDisclosure.isOpen}
          header="Excluir termo de consentimento?"
          text={`Você realmente deseja excluir o termo de consentimento?`}
          onHide={() => confirmarExclusaoDisclosure.close()}
          confirmText="Sim, excluir"
          onConfirm={() => onExcluirArquivo()}
        />
      )}
    </div>
  );
};

export default ConsentimentoPaciente;
