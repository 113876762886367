import { MenuItem } from 'primereact/menuitem';

import { formularioAuthorities } from 'src/pages/Emed/Admin/FormularioDinamico/utils';
import { ValidateKeys } from 'src/utils/TypeScript';

import { AssinaturaProntuarioList } from 'src/pages/Emed/Admin/AssinaturasProntuario/AssinaturasProntuario';
import { AssistenteVirtual } from 'src/pages/Emed/Admin/AssistenteVirtual/AssistenteVirtual';
import { AssistenteVirtualCreate } from 'src/pages/Emed/Admin/AssistenteVirtual/Create/AssistenteVirtualCreate';
import BloqueioPaciente from 'src/pages/Emed/Admin/BloqueioPaciente/BloqueioPaciente';
import Cadastros from 'src/pages/Emed/Admin/Cadastros';
import CentralComunicado from 'src/pages/Emed/Admin/Comunicado/CentralComunicado';
import Comunicado from 'src/pages/Emed/Admin/Comunicado/Comunicado';
import { CentralComunicadosPacientes } from 'src/pages/Emed/Admin/ComunicadosPacientes/CentralComunicadosPacientes';
import { ComunicadosPaciente } from 'src/pages/Emed/Admin/ComunicadosPacientes/ComunicadosPaciente';
import CentralConsultorio from 'src/pages/Emed/Admin/Consultorio/CentralConsultorio';
import Consultorio from 'src/pages/Emed/Admin/Consultorio/Consultorio';
import CentralConvenio from 'src/pages/Emed/Admin/Convenio/CentralConvenio';
import { FormConvenio } from 'src/pages/Emed/Admin/Convenio/FormConvenio/FormConvenio';
import CentralEmpresa from 'src/pages/Emed/Admin/Empresa/CentralEmpresa';
import Empresa from 'src/pages/Emed/Admin/Empresa/Empresa';
import CentralEspecialidade from 'src/pages/Emed/Admin/Especialidade/CentralEspecialidade';
import Especialidade from 'src/pages/Emed/Admin/Especialidade/Especialidade';
import CentroCusto from 'src/pages/Emed/Admin/Faturamento/CentroCusto/CentroCusto';
import ManterCusto from 'src/pages/Emed/Admin/Faturamento/CentroCusto/CentroCustoContent/ManterCusto/ManterCusto';
import ContasPagar from 'src/pages/Emed/Admin/Faturamento/ContasPagar/ContasPagar';
import ContasReceber from 'src/pages/Emed/Admin/Faturamento/ContasReceber/ContasReceber';
import Faturamento from 'src/pages/Emed/Admin/Faturamento/Faturamento';
import Fornecedor from 'src/pages/Emed/Admin/Faturamento/Fornecedor/Fornecedor';
import RelatoriosFaturamento from 'src/pages/Emed/Admin/Faturamento/RelatoriosFaturamento/RelatoriosFaturamento';
import { SelectedFaturamentosProvider } from 'src/pages/Emed/Admin/Faturamento/RelatoriosFaturamento/useSelectedFaturamentos';
import TipoContaPagar from 'src/pages/Emed/Admin/Faturamento/TipoContaPagar/TipoContaPagar';
import ManterTipoContaPagar from 'src/pages/Emed/Admin/Faturamento/TipoContaPagar/TipoContaPagarContent/ManterTipoContaPagar/ManterTipoContaPagar';
import TipoContaReceber from 'src/pages/Emed/Admin/Faturamento/TipoContaReceber/TipoContaReceber';
import ValoresServico from 'src/pages/Emed/Admin/Faturamento/ValoresServico/ValoresServico';
import AddValoresServicos from 'src/pages/Emed/Admin/Faturamento/ValoresServico/ValoresServicoContent/AddValoresServicos/AddValoresServicos';
import Feedback from 'src/pages/Emed/Admin/Feedback/Feedback';
import { FormularioDinamico } from 'src/pages/Emed/Admin/FormularioDinamico';
import { AcervoFormulariosCreate } from 'src/pages/Emed/Admin/FormularioDinamico/AcervoFormularios/Create/AcervoFormulariosCreate';
import { FormularioDinamicoAcervoFormularios } from 'src/pages/Emed/Admin/FormularioDinamico/AcervoFormularios/FormularioDinamicoAcervoFormularios';
import { FormularioDinamicoAcervoPerguntas } from 'src/pages/Emed/Admin/FormularioDinamico/AcervoPerguntas/FormularioDinamicoAcervoPerguntas';
import { AplicacaoFormularioCreate } from 'src/pages/Emed/Admin/FormularioDinamico/AplicacaoFormulario/Create/AplicacaoFormularioCreate';
import { FormularioDinamicoAplicacaoFormulario } from 'src/pages/Emed/Admin/FormularioDinamico/AplicacaoFormulario/FormularioDinamicoAplicacaoFormulario';
import { FormularioDinamicoGestaoMonitoramento } from 'src/pages/Emed/Admin/FormularioDinamico/GestaoMonitoramento/FormularioDinamicoGestaoMonitoramento';
import CentralHospital from 'src/pages/Emed/Admin/Hospital/CentralHospital';
import Hospital from 'src/pages/Emed/Admin/Hospital/Hospital';
import { CentralProtocoloAtendimento } from 'src/pages/Emed/Admin/ProtocoloAtendimento/CentralProtocoloAtendimento';
import { ProtocoloAtendimento } from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/ProtocoloAtendimento';
import CentralUsuario from 'src/pages/Emed/Admin/Usuario/CentralUsuario';
import ManterUsuario from 'src/pages/Emed/Admin/Usuario/ManterUsuario/ManterUsuario';
import Agenda from 'src/pages/Emed/Agenda/Agenda';
import ConfiguracoesAgenda from 'src/pages/Emed/Agenda/ConfiguracoesAgenda/ConfiguracoesAgenda';
import NovoAgendamento from 'src/pages/Emed/Agenda/NovoAgendamento/NovoAgendamento';
import ComponentsTests from 'src/pages/Emed/ComponentsTests/ComponentsTests';
import Cardapio from 'src/pages/Emed/Config/Cardapio/Cardapio';
import AddPerfil from 'src/pages/Emed/Config/Cardapio/Perfis/AddPerfil/AddPerfil';
import Config from 'src/pages/Emed/Config/Config';
import ConfiguracaoGuiaTiss from 'src/pages/Emed/Config/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTiss';
import AddConfiguracaoGuiaTiss from 'src/pages/Emed/Config/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissGlobal/AddConfiguracaoGuiaTiss/AddConfiguracaoGuiaTiss';
import ConfiguracaoTuss from 'src/pages/Emed/Config/ConfiguracaoTuss/ConfiguracaoTuss';
import FornecedorTuss from 'src/pages/Emed/Config/ConfiguracaoTuss/FornecedorTuss/FornecedorTuss';
import GrupoTuss from 'src/pages/Emed/Config/ConfiguracaoTuss/GrupoTuss/GrupoTuss';
import { FormPacote } from 'src/pages/Emed/Config/ConfiguracaoTuss/Pacote/FormPacote/FormPacote';
import { Pacote } from 'src/pages/Emed/Config/ConfiguracaoTuss/Pacote/Pacote';
import FormServicoTuss from 'src/pages/Emed/Config/ConfiguracaoTuss/ServicoTuss/FormServicoTuss/FormServicoTuss';
import ServicoTuss from 'src/pages/Emed/Config/ConfiguracaoTuss/ServicoTuss/ServicoTuss';
import Configuracoes from 'src/pages/Emed/Config/Configuracoes/Configuracoes';
import NovoLembrete from 'src/pages/Emed/Config/Configuracoes/LembreteAgendamento/NovoLembrete/FormLembrete';
import Parametros from 'src/pages/Emed/Config/Parametros/Parametros';
import AddParametro from 'src/pages/Emed/Config/Parametros/ParametrosGlobais/AddParametro/AddParametro';
import NovoModelo from 'src/pages/Emed/Config/PersonalizacaoProntuario/NovoModelo/NovoModelo';
import PersonalizacaoProntuario from 'src/pages/Emed/Config/PersonalizacaoProntuario/PersonalizacaoProntuario';
import Telemedicina from 'src/pages/Emed/Config/Telemedicina/Telemedicina';
import Home from 'src/pages/Emed/Home/Home';
import { Monitoramento } from 'src/pages/Emed/Monitoramentos/Monitoramentos';
import TransacoesTiss from 'src/pages/Emed/Monitoramentos/TransacoesTiss/TransacoesTiss';
import BuscaAvancada from 'src/pages/Emed/Pacientes/BuscaAvancada/BuscaAvancada';
import CarteiraAPS from 'src/pages/Emed/Pacientes/CarteiraAPS/CarteiraAPS';
import FormComplexidade from 'src/pages/Emed/Pacientes/CarteiraAPS/ComplexidadePaciente/FormComplexidade/FormComplexidade';
import FormEquipe from 'src/pages/Emed/Pacientes/CarteiraAPS/Equipe/FormEquipe/FormEquipe';
import FormMovimentacao from 'src/pages/Emed/Pacientes/CarteiraAPS/Movimentacao/EditarMovimentacao/FormMovimentacao';
import FormPontuacao from 'src/pages/Emed/Pacientes/CarteiraAPS/PontuacaoCarteira/NovaPontuacao/FormPontuacao';
import CentralPacientes from 'src/pages/Emed/Pacientes/CentralPacientes/CentralPacientes';
import ManterPaciente from 'src/pages/Emed/Pacientes/CentralPacientes/ManterPaciente/ManterPaciente';
import VisualizarPaciente from 'src/pages/Emed/Pacientes/CentralPacientes/VisualizarPaciente/VisualizarPaciente';
import ExportacaoProntuario from 'src/pages/Emed/Pacientes/ExportacaoProntuario/ExportacaoProntuario';
import AddGrupoAtendimento from 'src/pages/Emed/Pacientes/GrupoAtendimento/AddGrupoAtendimento/AddGrupoAtendimento';
import GrupoAtendimento from 'src/pages/Emed/Pacientes/GrupoAtendimento/GrupoAtendimento';
import CentralMigraPEP from 'src/pages/Emed/Pacientes/MigraPEP/CentralMigraPEP';
import Pacientes from 'src/pages/Emed/Pacientes/Pacientes';
import { PlanoCuidadoComp } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/Configuracoes';
import Prontuario from 'src/pages/Emed/Prontuario/Prontuario';
import Relatorios from 'src/pages/Emed/Relatorios/Relatorios';
import RelatoriosAgenda from 'src/pages/Emed/Relatorios/RelatoriosAgenda/RelatoriosAgenda';
import RelatoriosAtendimentos from 'src/pages/Emed/Relatorios/RelatoriosAtendimentos/RelatoriosAtendimentos';
import RelatoriosEmail from 'src/pages/Emed/Relatorios/RelatoriosEmail/RelatoriosEmail';
import RelatoriosEmailsAssinatura from 'src/pages/Emed/Relatorios/RelatoriosEmailsAssinatura/RelatoriosEmailsAssinatura';
import RelatoriosParto from 'src/pages/Emed/Relatorios/RelatoriosParto/RelatoriosParto';
import RelatoriosTrilhaAuditoria from 'src/pages/Emed/Relatorios/RelatoriosTrilhaAuditoria/RelatoriosTrilhaAuditoria';
export interface RouteItem extends ValidateKeys<MenuItem> {
  element: JSX.Element;
  route: string;
  label: string;
  hideFromMenu?: boolean;
  items?: RouteItem[];
  checkPermission?: string | string[];
}

export const RouterMap: RouteItem[] = [];

export const userRoutes: RouteItem[] = [
  {
    label: 'Teste',
    route: '/comp',
    element: <ComponentsTests />,
    hideFromMenu: true,
  },
  {
    label: 'Início',
    route: '/',
    element: <Home />,
  },
  {
    label: 'Agenda',
    route: '/agenda',
    element: <Agenda />,
    checkPermission: 'AGE_VISUALIZAR_AGENDA',
  },
  {
    label: 'Prontuário',
    route: '/prontuario',
    element: <Prontuario />,
    checkPermission: 'PRO_VISUALIZAR_PRONTUARIO',
    hideFromMenu: true,
  },
  {
    label: 'Pacientes',
    route: '/pacientes',
    element: <Pacientes />,
    items: [
      {
        label: 'Cadastro pacientes',
        route: '/pacientes/cadastro',
        element: <CentralPacientes />,
        checkPermission: 'ADM_PACIENTE_CONSULTAR',
      },
      // {
      //   //? HIDDEN
      //   label: 'Pendências de auditoria',
      //   route: '/pacientes/pendencias-auditoria',
      //   element: <PendenciasAuditoria />,
      // },
      {
        label: 'Assinatura de prontuários',
        route: '/pacientes/assinatura-prontuarios',
        element: <AssinaturaProntuarioList />,
        checkPermission: 'PRO_ASSINATURA_ELETRONICA',
      },
      {
        label: 'Grupos de atendimento',
        route: '/pacientes/grupo-atendimento',
        element: <GrupoAtendimento />,
        checkPermission: 'AGE_GRUPO_DE_ATENDIMENTO_CONSULTAR',
      },
      {
        label: 'Exportação de Prontuário',
        route: '/pacientes/exportacao-prontuario',
        element: <ExportacaoProntuario />,
        checkPermission: 'ADM_EXPORTACAO_PRONTUARIO',
      },
      {
        label: 'Migração de Prontuário',
        route: '/pacientes/migracao',
        element: <CentralMigraPEP />,
        checkPermission: 'ADM_MIGRA_PEP',
      },
      {
        label: 'Busca avançada geral',
        route: '/pacientes/busca-avancada',
        element: <BuscaAvancada />,
        checkPermission: 'PRO_BUSCA_AVANCADA',
      },
      {
        label: 'Carteira APS',
        route: '/pacientes/carteira-aps',
        element: <CarteiraAPS />,
        checkPermission: [
          'ADM_CARTEIRA_APS_PONTUACAO_CONSULTAR',
          'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_CONSULTAR',
          'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_CONSULTAR',
          'ADM_CARTEIRA_APS_PROFISSIONAL_CONSULTAR',
          'ADM_CARTEIRA_APS_EQUIPE_CONSULTAR',
          'ADM_CARTEIRA_APS_MOVIMETACAO_CONSULTAR',
        ],
      },
      {
        label: 'Cadastro - Complexidade do paciente',
        route: '/pacientes/carteira-aps/complexidade',
        element: <FormComplexidade />,
        hideFromMenu: true,
        checkPermission: 'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_CADASTRAR',
      },
      {
        label: 'Editar - Complexidade do paciente',
        route: '/pacientes/carteira-aps/complexidade/:idComplexidade',
        element: <FormComplexidade />,
        hideFromMenu: true,
        checkPermission: 'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_ALTERAR',
      },

      {
        label: 'Movimentação carteira APS',
        route: '/pacientes/carteira-aps/movimentacao/:id',
        element: <FormMovimentacao />,
        hideFromMenu: true,
        checkPermission: 'ADM_CARTEIRA_APS_MOVIMETACAO_ALTERAR',
      },
      {
        label: 'Cadastro - Equipe',
        route: '/pacientes/carteira-aps/equipe',
        element: <FormEquipe />,
        hideFromMenu: true,
        checkPermission: 'ADM_CARTEIRA_APS_EQUIPE_CADASTRAR',
      },
      {
        label: 'Editar - Equipe',
        route: '/pacientes/carteira-aps/equipe/:idEquipe',
        element: <FormEquipe />,
        hideFromMenu: true,
        checkPermission: 'ADM_CARTEIRA_APS_EQUIPE_ALTERAR',
      },
      {
        label: 'Plano de Cuidado',
        route: '/pacientes/plano-cuidado/configuracoes',
        element: <PlanoCuidadoComp />,
        checkPermission: [
          'ADM_PLANO_CUIDADO_CONFIGURACAO_CONSULTAR',
          'PRO_PLANO_CUIDADO_GESTAO_CONSULTAR',
        ],
      },
    ],
  },
  {
    label: 'Nova pontuação carteira APS',
    route: '/pacientes/carteira-aps/pontuacao',
    hideFromMenu: true,
    element: <FormPontuacao />,
    checkPermission: 'ADM_CARTEIRA_APS_PONTUACAO_CADASTRAR',
  },
  {
    label: 'Editar pontuação carteira APS',
    route: '/pacientes/carteira-aps/pontuacao/:id',
    hideFromMenu: true,
    element: <FormPontuacao />,
    checkPermission: 'ADM_CARTEIRA_APS_PONTUACAO_ALTERAR',
  },
  {
    label: 'Visualizar paciente',
    route: '/pacientes/visualizar/:idPaciente',
    element: <VisualizarPaciente />,
    hideFromMenu: true,
    checkPermission: 'ADM_PACIENTE_CONSULTAR',
  },
  {
    label: 'Novo paciente',
    route: '/pacientes/novo',
    element: <ManterPaciente />,
    hideFromMenu: true,
    checkPermission: 'ADM_PACIENTE_CADASTRAR',
  },
  {
    label: 'Editar paciente',
    route: '/pacientes/:idPaciente',
    element: <ManterPaciente />,
    hideFromMenu: true,
    checkPermission: 'ADM_PACIENTE_ALTERAR',
  },
  {
    label: 'Novo grupo de atendimento',
    route: '/pacientes/novo-grupo-atendimento',
    hideFromMenu: true,
    element: <AddGrupoAtendimento />,
    checkPermission: 'AGE_GRUPO_DE_ATENDIMENTO_CADASTRAR',
  },
  {
    label: 'Editar grupo de atendimento',
    route: '/pacientes/novo-grupo-atendimento/:id',
    hideFromMenu: true,
    element: <AddGrupoAtendimento />,
    checkPermission: 'AGE_GRUPO_DE_ATENDIMENTO_ALTERAR',
  },
  {
    label: 'Configurações da Agenda',
    route: '/agenda/config',
    element: <ConfiguracoesAgenda />,
    checkPermission: [
      'AGE_HORARIO_DE_ATENDIMENTO_CONSULTAR',
      'AGE_COMPROMISSO_CONSULTAR',
    ],
    hideFromMenu: true,
  },
  {
    label: 'Novo agendamento',
    route: '/agenda/novo-agendamento',
    hideFromMenu: true,
    element: <NovoAgendamento />,
    checkPermission: 'AGE_VISUALIZAR_AGENDA',
  },
  {
    label: 'Novo encaixe',
    route: '/agenda/novo-encaixe',
    hideFromMenu: true,
    element: <NovoAgendamento />,
    checkPermission: 'AGE_VISUALIZAR_AGENDA',
  },
  {
    label: 'Editar Lembrete',
    route: '/config/general/lembrete/:id',
    element: <NovoLembrete />,
    hideFromMenu: true,
    checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_ALTERAR',
  },
  {
    label: 'Novo Lembrete',
    route: '/config/general/lembrete',
    element: <NovoLembrete />,
    hideFromMenu: true,
    checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_CADASTRAR',
  },
  {
    label: 'Configurações',
    route: '/config',
    element: <Config />,
    items: [
      {
        label: 'Configurações Gerais',
        route: '/config/general',
        element: <Configuracoes />,
        checkPermission: [
          'AGE_TIPO_DE_CUIDADO_CONSULTAR',
          'AGE_MOTIVO_DE_ATENDIMENTO_CONSULTAR',
          'AGE_MOTIVO_DE_FALTA_CONSULTAR',
          'ADM_USUARIOS_SIMULTANEOS_CONSULTAR',
          'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_CONSULTAR',
        ],
      },
      {
        label: 'Termo de consentimento',
        route: '/config/telemedicina',
        element: <Telemedicina />,
        checkPermission: 'ADM_TERMO_DE_CONSENTIMENTO_CONSULTAR',
      },
      {
        label: 'Cardápio',
        route: '/config/cardapio',
        element: <Cardapio />,
        checkPermission: [
          'ADM_FUNCIONALIDADE_CONSULTAR',
          'ADM_CARDAPIO_CONSULTAR',
          'ADM_PERFIL_CONSULTAR',
        ],
      },
      {
        label: 'Parâmetros',
        route: '/config/parametros',
        element: <Parametros />,
        checkPermission: 'ADM_PARAMETROS_CONSULTAR',
      },
      {
        label: 'Personalização de Prontuário',
        route: '/config/personalizacao-prontuarios',
        element: <PersonalizacaoProntuario />,
        checkPermission: 'PRO_MODELO_PRONTUARIO_CONSULTAR',
      },
      {
        label: 'Configuração TISS',
        route: '/config/config-tiss',
        element: <ConfiguracaoGuiaTiss />,
        checkPermission: 'ADM_CONFIGURACAO_TISS_CONSULTAR',
      },
      {
        label: 'Configuração TUSS',
        route: '/config/config-tuss',
        element: <ConfiguracaoTuss />,
        items: [
          {
            label: 'Serviço TUSS',
            route: '/config/config-tuss/servico-tuss',
            element: <ServicoTuss />,
            checkPermission: 'ADM_SERVICO_TUSS_CONSULTAR',
          },
          {
            label: 'Grupos TUSS',
            route: '/config/config-tuss/grupo-tuss',
            element: <GrupoTuss />,
            checkPermission: 'ADM_GRUPO_TUSS_CONSULTAR',
          },
          {
            label: 'Fornecedor TUSS',
            route: '/config/config-tuss/fornecedor-tuss',
            element: <FornecedorTuss />,
            checkPermission: 'ADM_FORNECEDOR_TUSS_CONSULTAR',
          },
          {
            label: 'Pacote',
            route: '/config/config-tuss/pacote',
            element: <Pacote />,
            checkPermission: 'ADM_PACOTES_CONSULTAR',
          },
        ],
      },
    ],
  },
  {
    label: 'Novo parâmetro',
    route: '/config/parametros/novo',
    element: <AddParametro />,
    checkPermission: 'ADM_PARAMETROS_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Personalização parâmetro',
    route: '/config/parametros/novo/:id',
    element: <AddParametro />,
    checkPermission: 'ADM_PARAMETROS_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Edição modelo de Prontuário',
    route: '/config/personalizacao-prontuarios/:idModelo',
    element: <NovoModelo />,
    checkPermission: 'PRO_MODELO_PRONTUARIO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Novo modelo de Prontuário',
    route: '/config/personalizacao-prontuarios/novo',
    element: <NovoModelo />,
    checkPermission: 'PRO_MODELO_PRONTUARIO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastros',
    route: '/cadastros',
    element: <Cadastros />,
    items: [
      {
        label: 'Usuários',
        route: '/cadastros/usuario',
        element: <CentralUsuario />,
        checkPermission: 'ADM_USUARIO_CONSULTAR',
      },
      {
        label: 'Consultórios',
        route: '/cadastros/consultorio',
        element: <CentralConsultorio />,
        checkPermission: 'ADM_CONSULTORIO_CONSULTAR',
      },
      {
        label: 'Hospitais',
        route: '/cadastros/hospital',
        element: <CentralHospital />,
        checkPermission: 'ADM_HOSPITAL_CONSULTAR',
      },
      {
        label: 'Comunicados',
        route: '/cadastros/comunicado',
        element: <CentralComunicado />,
        checkPermission: 'ADM_COMUNICADO_CONSULTAR',
      },
      {
        label: 'Comunicados Pacientes',
        route: '/cadastros/comunicadosPacientes',
        element: <CentralComunicadosPacientes />,
        checkPermission: 'ADM_COMUNICADO_CONSULTAR',
      },
      {
        label: 'Especialidades',
        route: '/cadastros/especialidade',
        element: <CentralEspecialidade />,
        checkPermission: 'ADM_ESPECIALIDADE_CONSULTAR',
      },
      {
        label: 'Clientes',
        route: '/cadastros/cliente',
        element: <CentralEmpresa />,
        checkPermission: 'ADM_EMPRESA_CONSULTAR',
      },
      {
        label: 'Convênios',
        route: '/cadastros/convenio',
        element: <CentralConvenio />,
        checkPermission: 'ADM_CONVENIO_CONSULTAR',
      },
      {
        label: 'Assistente Virtual',
        route: '/cadastros/assistente-virtual',
        element: <AssistenteVirtual />,
        checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_CONSULTAR',
      },
      {
        label: 'Formulário Dinâmico',
        route: '/cadastros/formulario-dinamico',
        element: <FormularioDinamico />,
        checkPermission: formularioAuthorities,
      },
      {
        label: 'Protocolo de atendimento',
        route: '/cadastros/protocolo-atendimento',
        element: <CentralProtocoloAtendimento />,
        checkPermission: 'ADM_PROTOCOLO_ATENDIMENTO_CONSULTAR',
      },
    ],
  },
  {
    label: 'Relatórios',
    route: '/relatorios',
    element: <Relatorios />,
    items: [
      {
        label: 'Agenda',
        route: '/relatorios/agenda',
        element: <RelatoriosAgenda />,
        checkPermission: 'ADM_REL_AGENDA',
      },
      {
        label: 'Atendimentos',
        route: '/relatorios/atendimentos',
        element: <RelatoriosAtendimentos />,
        checkPermission: 'ADM_REL_ATENDIMENTO',
      },
      {
        label: 'E-mails de assinatura',
        route: '/relatorios/emails-assinatura',
        element: <RelatoriosEmailsAssinatura />,
        checkPermission: 'ADM_HISTORICO_EMAILS_DOC_ASSINADOS',
      },
      {
        label: 'E-mails telemedicina',
        route: '/relatorios/e-mails-telemedicina',
        element: <RelatoriosEmail />,
        checkPermission: 'ADM_HISTORICO_EMAIL_TELEMEDICINA',
      },
      {
        label: 'Trilha de auditoria',
        route: '/relatorios/trilha-auditoria',
        element: <RelatoriosTrilhaAuditoria />,
        checkPermission: 'ADM_REL_TRILHA_AUDITORIA',
      },
      {
        label: 'Partos',
        route: '/relatorios/relatorios-parto',
        element: <RelatoriosParto />,
        checkPermission: 'ADM_REL_PREVISAO_PARTO',
      },
    ],
  },
  {
    label: 'Cadastro - Hospital',
    route: '/cadastros/hospital/cadastro',
    element: <Hospital />,
    checkPermission: 'ADM_HOSPITAL_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Hospital',
    route: '/cadastros/hospital/cadastro/:id',
    element: <Hospital />,
    checkPermission: 'ADM_HOSPITAL_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Usuário',
    route: '/cadastros/usuario/cadastro',
    element: <ManterUsuario />,
    checkPermission: 'ADM_USUARIO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Editar - Usuário',
    route: '/cadastros/usuario/cadastro/:id',
    element: <ManterUsuario />,
    checkPermission: 'ADM_USUARIO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Cardápio',
    route: '/cadastros/cardapio/cadastro',
    element: <Cardapio />,
    checkPermission: 'ADM_CARDAPIO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Consultório',
    route: '/cadastros/consultorio/cadastro',
    element: <Consultorio />,
    checkPermission: 'ADM_CONSULTORIO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Consultório',
    route: '/cadastros/consultorio/cadastro/:id',
    element: <Consultorio />,
    checkPermission: 'ADM_CONSULTORIO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Especialidade',
    route: '/cadastros/especialidade/cadastro',
    element: <Especialidade />,
    hideFromMenu: true,
    checkPermission: 'ADM_ESPECIALIDADE_CADASTRAR',
  },
  {
    label: 'Cadastro - Especialidade',
    route: '/cadastros/especialidade/cadastro/:id',
    element: <Especialidade />,
    hideFromMenu: true,
    checkPermission: 'ADM_ESPECIALIDADE_ALTERAR',
  },
  {
    label: 'Cadastro - Feedback',
    route: '/cadastros/feedback/cadastro',
    element: <Feedback />,
    hideFromMenu: true,
    checkPermission: 'ADM_FEEDBACK',
  },
  {
    label: 'Cadastro - Comunicado',
    route: '/cadastros/comunicado/cadastro',
    element: <Comunicado />,
    checkPermission: 'ADM_COMUNICADO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Comunicado',
    route: '/cadastros/comunicadosPacientes/cadastro',
    element: <ComunicadosPaciente />,
    checkPermission: 'ADM_COMUNICADO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Comunicado',
    route: '/cadastros/comunicado/cadastro/:id',
    element: <Comunicado />,
    checkPermission: 'ADM_COMUNICADO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Comunicado',
    route: '/cadastros/comunicadosPacientes/cadastro/:id',
    element: <ComunicadosPaciente />,
    checkPermission: 'ADM_COMUNICADO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Novo Convênio',
    route: '/cadastros/convenio/novo',
    element: <FormConvenio />,
    checkPermission: 'ADM_CONVENIO_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Editar Convenio',
    route: '/cadastros/convenio/:idConvenio',
    element: <FormConvenio />,
    checkPermission: 'ADM_CONVENIO_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Cadastro - Assistente Virtual',
    route: '/cadastros/assistente-virtual/cadastro',
    element: <AssistenteVirtualCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_CADASTRAR',
  },
  {
    label: 'Editar - Assistente Virtual',
    route: '/cadastros/assistente-virtual/:id',
    element: <AssistenteVirtualCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_ALTERAR',
  },
  {
    label: 'Cadastro - Protocolo de atendimento',
    route: '/cadastros/protocolo-atendimento/cadastro',
    element: <ProtocoloAtendimento />,
    hideFromMenu: true,
    checkPermission: 'ADM_PROTOCOLO_ATENDIMENTO_CADASTRAR',
  },
  {
    label: 'Editar - Protocolo de atendimento',
    route: '/cadastros/protocolo-atendimento/:id',
    element: <ProtocoloAtendimento />,
    hideFromMenu: true,
    checkPermission: 'ADM_PROTOCOLO_ATENDIMENTO_ALTERAR',
  },
  {
    label: 'Outras configurações',
    route: '/config/bloqueio-paciente',
    element: <BloqueioPaciente />,
    hideFromMenu: true,
    checkPermission: [
      'ADM_BLOQUEIO_PACIENTES_CONSULTAR',
      'ADM_TEMPLATE_ANIVERSARIO_CONSULTAR',
    ],
  },
  {
    label: 'Monitoramento',
    route: '/monitoramento',
    element: <Monitoramento />,
    items: [
      {
        label: 'Transações TISS',
        route: '/monitoramento/transacoesTiss',
        element: <TransacoesTiss />,
        checkPermission: ['ADM_MONITORAMENTO_TISS', 'ADM_PENDENCIAS_TISS'],
      },
    ],
  },
  {
    label: 'Configurar novo perfil de usuário',
    route: '/config/cardapio/perfil',
    element: <AddPerfil />,
    checkPermission: 'ADM_PERFIL_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Configurar novo perfil de usuário',
    route: '/config/cardapio/perfil/:id',
    element: <AddPerfil />,
    checkPermission: 'ADM_PERFIL_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Novo Cliente',
    route: '/cadastros/empresa/cadastro',
    element: <Empresa />,
    checkPermission: 'ADM_EMPRESA_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Editar Cliente',
    route: '/cadastros/empresa/cadastro/:id',
    element: <Empresa />,
    checkPermission: 'ADM_EMPRESA_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Nova guia TISS',
    route: '/config/config-tiss/novo',
    element: <AddConfiguracaoGuiaTiss />,
    checkPermission: 'ADM_CONFIGURACAO_TISS_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Personalização parâmetro',
    route: '/config/config-tiss/:idConfigTiss',
    element: <AddConfiguracaoGuiaTiss />,
    checkPermission: 'ADM_CONFIGURACAO_TISS_ALTERAR',
    hideFromMenu: true,
  },
  {
    label: 'Novo registro TUSS',
    route: '/config/config-tuss/servico-tuss/adicionar',
    element: <FormServicoTuss />,
    checkPermission: 'ADM_SERVICO_TUSS_CADASTRAR',
    hideFromMenu: true,
  },
  {
    label: 'Editar registro TUSS',
    route: '/config/config-tuss/servico-tuss/adicionar/:id',
    element: <FormServicoTuss />,
    checkPermission: 'ADM_SERVICO_TUSS_ALTERAR',
    hideFromMenu: true,
  },

  {
    label: 'Novo pacote',
    route: '/config/config-tuss/pacote/novo',
    element: <FormPacote />,
    hideFromMenu: true,
    checkPermission: 'ADM_PACOTES_CADASTRAR',
  },
  {
    label: 'Editar pacote',
    route: '/config/config-tuss/pacote/:idPacote',
    element: <FormPacote />,
    hideFromMenu: true,
    checkPermission: 'ADM_PACOTES_EDITAR',
  },
  {
    label: 'Financeiro',
    route: '/faturamento',
    element: <Faturamento />,
    items: [
      /*{
        label: 'Cadastrar fornecedor',
        route: '/faturamento/fornecedor',
        element: <Fornecedor />,
      },*/
      {
        label: 'Valor de serviços',
        route: '/faturamento/valores-servico',
        element: <ValoresServico />,
        checkPermission: 'ADM_SERVICOS_FATURAMENTO_CONSULTAR',
      },
      {
        label: 'Relatório de faturamento',
        route: '/faturamento/relatorio',
        element: (
          <SelectedFaturamentosProvider>
            <RelatoriosFaturamento />
          </SelectedFaturamentosProvider>
        ),
        checkPermission: 'ADM_REL_FATURAMENTO',
      },
      {
        label: 'Centro de custo',
        route: '/faturamento/centro-custo',
        element: <CentroCusto />,
        checkPermission: 'ADM_MANTER_CENTRO_CUSTO_CONSULTAR',
      },
      {
        label: 'Tipo de conta a pagar',
        route: '/faturamento/tipo-conta-pagar',
        element: <TipoContaPagar />,
        checkPermission: 'ADM_MANTER_TIPO_CONTA_PAGAR_CONSULTAR',
      },
      /*{
        label: 'Tipo de conta a receber',
        checkPermission: 'ADM_MANTER_TIPO_CONTA_RECEBER_CONSULTAR',
        route: '/faturamento/tipo-conta-receber',
        element: <TipoContaReceber />,
        checkPermission: 'ADM_MANTER_TIPO_CONTA_RECEBER_CONSULTAR',
      },
      {
        label: 'Contas a pagar',
        checkPermission: 'ADM_CONTAS_PAGAR_CONSULTAR',
        route: '/faturamento/contas-pagar',
        element: <ContasPagar />,
        checkPermission: 'ADM_CONTAS_PAGAR_CONSULTAR',
      },
      {
        label: 'Contas a receber',
        checkPermission: 'ADM_CONTAS_RECEBER_CONSULTAR',
        route: '/faturamento/contas-receber',
        element: <ContasReceber />,
        checkPermission: 'ADM_CONTAS_RECEBER_CONSULTAR',
      },*/
    ],
  },
  // {
  //   label: 'Cadastrar novo fornecedor',
  //   route: '/faturamento/fornecedor/novo',
  //   element: <AddFornecedor />,
  //   hideFromMenu: true,
  // },
  {
    label: 'Cadastrar valor de serviço',
    route: '/faturamento/novo-valores-servico',
    element: <AddValoresServicos />,
    hideFromMenu: true,
    checkPermission: 'ADM_SERVICOS_FATURAMENTO_CADASTRAR',
  },
  {
    label: 'Cadastrar centro de custo',
    route: '/faturamento/centro-custo/novo',
    element: <ManterCusto />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_CENTRO_CUSTO_CADASTRAR',
  },
  {
    label: 'Alterar centro de custo',
    route: '/faturamento/centro-custo/:id',
    element: <ManterCusto />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_CENTRO_CUSTO_ALTERAR',
  },
  {
    label: 'Novo tipo de conta a pagar',
    route: '/faturamento/tipo-conta-pagar/novo',
    element: <ManterTipoContaPagar />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_TIPO_CONTA_PAGAR_CADASTRAR',
  },
  {
    label: 'Alterar tipo de conta a pagar',
    route: '/faturamento/tipo-conta-pagar/:id',
    element: <ManterTipoContaPagar />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_TIPO_CONTA_PAGAR_ALTERAR',
  },
  {
    label: 'Acervo de perguntas - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/acervo-perguntas',
    element: <FormularioDinamicoAcervoPerguntas />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_CONSULTAR',
  },
  {
    label: 'Acervo de formulários - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/acervo-formularios',
    element: <FormularioDinamicoAcervoFormularios />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_FORMULARIO_DINAMICO_CONSULTAR',
  },
  {
    label: 'Criar formulário - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/acervo-formularios/novo',
    element: <AcervoFormulariosCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_FORMULARIO_DINAMICO_CADASTRAR',
  },
  {
    label: 'Editar formulário - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/acervo-formularios/:id',
    element: <AcervoFormulariosCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_FORMULARIO_DINAMICO_ALTERAR',
  },
  {
    label: 'Aplicação de formulários - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/aplicacao',
    element: <FormularioDinamicoAplicacaoFormulario />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CONSULTAR',
  },
  {
    label: 'Criar aplicação - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/aplicacao/novo',
    element: <AplicacaoFormularioCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CADASTRAR',
  },
  {
    label: 'Editar aplicação - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/aplicacao/:id',
    element: <AplicacaoFormularioCreate />,
    hideFromMenu: true,
    checkPermission: 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_ALTERAR',
  },
  {
    label: 'Gestão e monitoramento - Formulário dinâmico',
    route: '/cadastros/formulario-dinamico/gestao-monitoramento',
    element: <FormularioDinamicoGestaoMonitoramento />,
    hideFromMenu: true,
    checkPermission: 'ADM_GESTAO_MONITORAMENTO_FORMULARIO_DINAMICO',
  },
];
