import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { FieldPacientePreNatal } from './components/FieldPacientePreNatal';
import { FieldProfissionalSaudePreNatal } from './components/FieldProfissionalSaudePreNatal';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

type FormData = {
  periodo: string;
  idPaciente: number;
  idProfissionalSaude: number;
  examesSolicitados: boolean;
};

const RelatoriosParto = () => {
  const { consultorios, user } = useAppSelector((state: RootState) => state);
  const idConsultorio: number = consultorios?.ativo?.id;

  const schema = Yup.object().shape({
    periodo: Yup.array()
      .test(
        'oneOfRequired',
        'É necessário adicionar o periodo de tempo, com data inicial e final',
        function (item: any) {
          let response = false;
          if (item) {
            response = item[1] !== null;
          }

          return response;
        },
      )
      .typeError(
        'É necessário adicionar o periodo de tempo, com data inicial e final',
      ),
    idProfissionalSaude: Yup.number().when([], {
      is: () => user.tipoUsuario !== 'PROFISSIONAL_SAUDE',
      then: Yup.number().required('Campo obrigatório'),
      otherwise: Yup.number(),
    }),
  });

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      examesSolicitados: false,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data: FormData) => {
    const { periodo, ...rest } = data;
    const payload = {
      ...(user.tipoUsuario === 'PROFISSIONAL_SAUDE' && {
        idProfissionalSaude: user.idUsuario,
      }),
      idConsultorio,
      dataPrevisaoInicio: dayjs(periodo[0]).format('YYYY-MM-DD'),
      dataPrevisaoFinal: dayjs(periodo[1]).format('YYYY-MM-DD'),
      ...rest,
    };

    try {
      const response = await ReportAPI.relatorioParto(payload);
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className="p-grid p-col-12 p-lg-5 p-pb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CalendarInputControlled
          name="periodo"
          control={control}
          hideOnDateTimeSelect
          showIcon
          label="Período"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          selectionMode="range"
          className="p-col-12"
          errorMsg={errors.periodo?.message}
        />

        <FieldPacientePreNatal
          label="Nome do paciente"
          className="p-col-12"
          showClear
        />

        {user.tipoUsuario === 'PROFISSIONAL_SAUDE' ? null : (
          <FieldProfissionalSaudePreNatal
            label="Nome do profissional de saúde"
            idConsultorio={idConsultorio}
          />
        )}

        <div className="p-field-checkbox p-col-12">
          <CheckboxControlled
            name="examesSolicitados"
            control={control}
            label="Exames solicitados"
          />
        </div>

        <div className="p-d-flex p-flex-column p-gap-2 p-px-2">
          <SimpleText fontColor="color_40">
            Para concluir, gere o relatório
          </SimpleText>

          <Button
            type="submit"
            label="Gerar relatório em PDF"
            btnType="tonal"
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default RelatoriosParto;
